import { BottomSheet, Input } from "@clipboard-health/ui-components";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { calculateGeoDistanceInMiles } from "@src/appV2/Location";
import { type GeoLocation } from "@src/appV2/Location/types";
import { useEffect, useRef, useState } from "react";

import { IconButton } from "../../components/IconButton";
import { useGeoLocationFromUrlParams } from "../../GeoLocation/useGeoLocationFromUrlParams";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import type { MapViewWorkplace } from "../MapView/types";
import { useMapViewWorkplacesData } from "../MapView/useMapViewWorkplacesData";
import { WorkplacesList } from "./List";

interface WorkplacesListBottomSheetProps {
  modalState: UseModalState;
}

const MAX_DISTANCE_TO_FETCH = 1000;
const DISTANCE_TO_FETCH_INCREMENT = DEFAULT_DISTANCE_IN_MILES_FILTER;

function sortWorkplacesByDistance(workplaces: MapViewWorkplace[], geoLocationFilter: GeoLocation) {
  return workplaces.sort((a, b) => {
    const distanceA = calculateGeoDistanceInMiles(geoLocationFilter, a.attributes.location);
    const distanceB = calculateGeoDistanceInMiles(geoLocationFilter, b.attributes.location);
    return distanceA - distanceB;
  });
}

function useMaxDistanceFetched(
  geoLocationFilter: GeoLocation,
  distanceFilter: number,
  furthestWorkplace?: MapViewWorkplace
) {
  const { distanceInMiles: furthestWorkplaceDistance } = useDistanceToWorkplace({
    workplaceGeoLocation: furthestWorkplace?.attributes.location,
    workerGeoLocation: geoLocationFilter,
  });

  // round to the nearest 50 miles
  const roundedDistance = Math.round(Math.ceil(furthestWorkplaceDistance ?? 0) / 50) * 50;

  return roundedDistance > distanceFilter ? roundedDistance : distanceFilter;
}

export function WorkplacesListBottomSheet(props: WorkplacesListBottomSheetProps) {
  const { modalState } = props;

  const { geoLocation: geoLocationFromUrlParams } = useGeoLocationFromUrlParams();

  const {
    data: { mapViewWorkplaces, distanceFilter, geoLocationFilter: lastestGeoLocationFilter },
    isLoading,
    isSuccess,
    isError,
    fetchMore,
  } = useMapViewWorkplacesData({
    geoLocation: geoLocationFromUrlParams,
    shouldFetchUrgentShifts: false,
  });

  const geoLocationFilter = geoLocationFromUrlParams ?? lastestGeoLocationFilter;

  const [isFetchingFurther, setIsFetchingFurther] = useState(false);
  const isLoadingWorkplaces = isLoading || isFetchingFurther;

  // Fetch more workplaces increasing the distance radius
  // until workplaces are found or the max distance is reached.
  useEffect(() => {
    if (!isSuccess || mapViewWorkplaces.length > 0 || distanceFilter >= MAX_DISTANCE_TO_FETCH) {
      setIsFetchingFurther(false);
      return;
    }

    setIsFetchingFurther(true);
    fetchMore(geoLocationFilter, distanceFilter + DISTANCE_TO_FETCH_INCREMENT);
  }, [mapViewWorkplaces, distanceFilter, geoLocationFilter, fetchMore, isSuccess]);

  const stackRef = useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const sortedWorkplaces = sortWorkplacesByDistance(mapViewWorkplaces, geoLocationFilter);

  const maxDistanceFetched = useMaxDistanceFetched(
    geoLocationFilter,
    distanceFilter,
    sortedWorkplaces.at(-1)
  );

  const matchingWorkplaces =
    searchTerm === ""
      ? sortedWorkplaces
      : sortedWorkplaces.filter((workplace) =>
          workplace.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

  return (
    <BottomSheet modalState={modalState}>
      <DialogContent sx={{ gap: 6, display: "flex", flexDirection: "column" }}>
        <Stack sx={{ position: "relative", pt: 3 }}>
          <Stack spacing={3}>
            <Text semibold variant="h5" align="center">
              Browse all workplaces
            </Text>
            <Text
              semibold
              align="center"
              variant="caption"
              color={(theme) => theme.palette.text.secondary}
              sx={{ opacity: isLoadingWorkplaces ? 0 : 1 }}
            >
              with open shifts within {maxDistanceFetched} miles from your center location
            </Text>
          </Stack>
          <IconButton
            size="small"
            variant="outlined"
            iconType="close"
            sx={(theme) => ({
              boxShadow: theme.shadows[2],
              outlineColor: theme.palette.border?.subtle,
              position: "absolute",
              right: 0,
              top: 0,
            })}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.BROWSE_WORKPLACES_CLOSED);
              modalState.closeModal();
            }}
          />
        </Stack>

        <Input
          placeholder="Search for a workplace"
          iconType="search"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />

        <Stack
          ref={stackRef}
          sx={(theme) => ({
            height: "100%",
            minHeight: theme.size?.bottomSheet.loading.minHeight,
            justifyContent: isLoadingWorkplaces ? "center" : "flex-start",
            overflowY: "auto",
          })}
        >
          <WorkplacesList
            isLoading={isLoadingWorkplaces}
            isEmpty={sortedWorkplaces.length === 0}
            isError={isError}
            isSuccess={isSuccess}
            allWorkplaces={sortedWorkplaces}
            visibleWorkplaces={matchingWorkplaces}
            scrollRef={stackRef}
            searchTerm={searchTerm}
            isSearchEmpty={matchingWorkplaces.length === 0}
            totalCount={sortedWorkplaces.length}
            distanceFilter={maxDistanceFetched}
            geoLocationFilter={geoLocationFilter}
          />
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
