import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location/deviceLocation";
import { minutesToMilliseconds } from "date-fns";

export function usePreFetchGeolocation() {
  const geoLocationPrefetch = useCbhFlag(CbhFeatureFlag.GEOLOCATION_PREFETCH_FOR_BOOKING, {
    defaultValue: { enabled: false },
  });

  useDeviceGeoLocationIfAllowed({
    staleTime: geoLocationPrefetch.enabled
      ? minutesToMilliseconds(geoLocationPrefetch.staleTimeInMinutes)
      : undefined,
    enabled: geoLocationPrefetch.enabled,
  });
}
